
// - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - Responsive
// Usage: @include mq(tabletp) {}

$break-phonel: 480px;
$break-tabletp: 768px;
$break-tabletl: 1024px;
$break-laptop: 1220px;
$break-desktop: 1600px;

@mixin mq($media) {
  @if $media == phones {
		@media only screen and (max-width: $break-phonel) { @content; }
	}
	@else if $media == phonel {
		@media only screen and (min-width: $break-phonel) { @content; }
	}
	@else if $media == tabletp {
		@media only screen and (min-width: $break-tabletp) { @content; }
	}
	@else if $media == tabletl {
		@media only screen and (min-width: $break-tabletl) { @content; }
	}
	@else if $media == laptop {
		@media only screen and (min-width: $break-laptop) { @content; }
	}
	@else if $media == desktop {
		@media only screen and (min-width: $break-desktop) { @content; }
	}
}
