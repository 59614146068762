.service-single {
  h1.title {
    font-size: 3rem;
    font-family: $font-family-heading;
    color: $color-primary;
  }
  .content {
    > p {
      &:first-of-type {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 40px;
        font-weight: 300;
      }
    }
  }
}

.service-summary {
  display: flex;
  align-items: flex-start;
  padding: 2rem;
  height: 100%;

  background-image: linear-gradient(-15deg,
    rgba(250, 250, 255, 1),
    rgba(250, 250, 255, .85)
  );

  box-shadow: 0.05rem 0.05rem 0.15rem rgba(0,0,0,0.25);
  border-radius: 12px;

  .service-image {
    flex: 0 0 100px;
    margin-right: 10px;

    img {
      max-width: 100%;
      height: auto;
    }
  }

  .service-content {
    flex: 1;

    .service-title {
      font-family: $font-family-heading;
      font-size: 1.2rem;
      min-height: 3.5rem;
    }

    a {
      text-decoration: none;
      transition: all 325ms ease-in 0s;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}
