// Post listing (cards)
.posts,
.post__wrapper {
  display: flex;
  margin: 0;

  @include mq(phones) {
    width: 100%;
    padding: 1rem 1rem;
  }
  @include mq(phonel) {
    width: 100%;
    padding: 1rem 1.5rem;
  }
  @include mq(tabletp) {
    padding: 1.5rem 4rem;
  }
  @include mq(tabletl) {
    padding: 1.75rem 8rem;
  }
  @include mq(laptop) {
    padding: 2rem 16rem;
  }

  .flex-wrap {
    display: flex;
    flex-flow: wrap;
    align-content: space-between;
  }

  .flex-3 {
    display: flex;
    flex-flow: column wrap;
    align-content: space-between;
    max-height: 100vh;

    &::before,
    &::after {
      content: "";
      flex-basis: 100%;
      width: 0;
      order: 2;
    }
  }

  .post__date {
    .time__icon {
      font-size: 1.6 * $small-font-size;
    }
    color: $color-gray-light;
    font-size: $small-font-size;
    margin-top: .5rem;
  }

  .post__author {
    line-height: $line-height-base * 2;
    margin: .5rem 0;
  }

  a.post__title {
    color: #333;
    display: block;
    text-transform: none;
    text-decoration: none;
    padding: 0 0 .5rem 0;
  }

  /* Force new columns */
  .card {
    border-radius: 4px;
    // border: 1px solid #ccc;
    margin-bottom: 1rem;
    padding: 2rem;
    box-sizing: border-box;
    text-align: left;
    color: white;

    @include mq(phones) {
      width: 100%;
    }
    @include mq(phonel) {
      width: 100%;
    }
    @include mq(tabletp) {
      width: 50%;
      flex-grow: 0;
    }
    @include mq(tabletl) {
      width: 50%;
    }
    @include mq(laptop) {
      width: 50%;
    }
  }

  .posts-container {
    text-align: center;

    .card:first-child {
      background-color: lighten($color-primary, 44);
      width: 100%;
    }
    // Do something funky once we enable pagination
    // @for $i from 1 through 12{
    //   div:nth-child(#{$i}) {
    //     $h: (random(400) + 200) + px;
    //     width: $h;
    //     order: $i;
    //   }
    // }
  }

}

// Individual post pages
.post__wrapper {
  @include mq(phones) {
    padding: 0 1rem;
  }
  @include mq(phonel) {
    padding: 0 1.5rem;
  }
  @include mq(tabletp) {
    padding: 1rem 4rem;
  }
  @include mq(tabletl) {
    padding: 1rem 8rem;
  }
  @include mq(laptop) {
    padding: 1rem 16rem;
  }

  .post__header {
    padding: 1rem 0 3rem 0;
  }

  .bg-cover {
    background-position: center;
    background-size: cover;
    width: 100%;
    margin-bottom: 2rem;
    @include mq(phones) { height: 260px; }
    @include mq(phonel) { height: 300px; }
    @include mq(tabletp) { height: 360px; }
    @include mq(tabletl) { height: 360px; }
    @include mq(laptop) { height: 420px; }
  }
}
