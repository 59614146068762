*:focus {
  outline: 0;
}

#modal_opener {
  background: #3498db;
  padding: 4px 0;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  border-radius: 3px;
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  font-size: 16px;
  padding: 10px 20px;
  border: none;
  transition: background 0.2s ease;

  &:hover {
    cursor: pointer;
    background: #469fdb;
  }
}

#modal_opener::after {
  content: "";
  display: block;
  position: absolute;
  left: 50%;
  width: 100%;
  height: 10%;
  border-radius: 50%;
  background-color: rgba(0,0,0,0.4);
  opacity: 0.4;
  transform: translateX(-50%) translateY(-50%);
  bottom: -30px;
}

.modal {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1041;

  .overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 995;
    background: rgba(0,0,0,0.6);
  }

  .modal_content {
    z-index: 999;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-height: 90%;
    overflow: auto;
    background: #fff;
    padding: 20px;
    box-shadow: 0 1px 5px rgba(0,0,0,0.7);
    text-align: center;
    border-radius: 4px;
    width: 520px; /* This just a default width */

    h2 {
      text-align: center;
    }
  }

}

.modal .modal_content .buttons_wrapper {
  padding: 20px;
}

