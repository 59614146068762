$color-main: $color-primary;
$color-active: #fff;
$color-link: #fff;

$button-height: 27px;
$button-width: 35px;

.button_container {
  position: fixed;
  top: 5%;
  right: 2%;
  height: $button-height;
  width: $button-width;
  cursor: pointer;
  z-index: 100;
  transition: opacity 0.25s ease;

  &:hover {
    opacity: 0.7;
  }

  &.active {
    .top {
      transform: translateY(11px) translateX(0) rotate(45deg);
      background: $color-active;
    }
    .middle {
      opacity: 0;
      background: $color-active;
    }

    .bottom {
      transform: translateY(-11px) translateX(0) rotate(-45deg);
      background: $color-active;
    }
  }

  span {
    background: $color-main;
    border: none;
    height: 5px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all 0.35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 11px;
    }

    &:nth-of-type(3) {
      top: 22px;
    }
  }
}

.main-menu-mobile {
  position: fixed;
  background: lighten($color-secondary, 65);
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.35s, visibility 0.35s, height 0.35s;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  hr {
    border-top: 1px solid rgba(255, 255, 255, 0.5);
  }

  &.open {
    opacity: 0.95;
    visibility: visible;
    height: 100%;
    z-index: 10;

    ul {
      li {
        animation: fadeInRight 0.5s ease forwards;
        animation-delay: 0.35s;
        color: $color-primary;

        a {
          font-weight: 400;
          text-transform: lowercase;
        }

        &:nth-of-type(2) {
          animation-delay: 0.4s;
        }
        &:nth-of-type(3) {
          animation-delay: 0.45s;
        }
        &:nth-of-type(4) {
          animation-delay: 0.5s;
        }
        &:nth-of-type(5) {
          animation-delay: 0.55s;
        }
        &:nth-of-type(6) {
          animation-delay: 0.6s;
        }
        &:nth-of-type(7) {
          animation-delay: 0.65s;
        }
        &:nth-of-type(8) {
          animation-delay: 0.8s;
        }
        &:nth-of-type(9) {
          animation-delay: 0.85s;
        }
        &:nth-of-type(10) {
          animation-delay: 0.9s;
        }
      }
    }
  }


  ul {
    color: $color-primary;
    font-size: 2rem;
    font-family: $font-family-heading;
    text-align: center;
    list-style: none;
    padding: 0;
    margin: 0;
    flex: 0;

    li {
      display: block;
      position: relative;
      opacity: 0;
      padding: 0 .25rem;

      a {
        display: block;
        position: relative;
        color: $color-secondary;
        font-weight: 200;
        text-decoration: none;
        overflow: hidden;
        letter-spacing: 0.5px;

        &.button {
          // To overwrite button styles in mobile menu
          color: $color-secondary;
          white-space: inherit;
          padding: inherit;
          background-color: inherit;
          font-size: inherit;

          &:hover {
            background: inherit !important;
            box-shadow: inherit;
          }
        }

        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &.current {
          opacity: 0.6;
          text-decoration: underline;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: $color-secondary;
          transition: 0.35s;
        }
      }
    }
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}

.lock-scroll {
  overflow: hidden;
}
