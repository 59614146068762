// Copy/Pasta
  .hamburger .hamburger-inner,
  .hamburger .hamburger-inner::before,
  .hamburger .hamburger-inner::after {
    background: $color-primary !important;
  }

.banner_part .banner_item {
  display: flex;
  justify-content: space-between;
  padding: 2rem 0 6rem;

  h5 {
    color: $color-primary;
  }
}
.banner_item {
  .single_item {
    text-align: center;
    margin-top: 20px;
    margin-bottom: 10px;
    position: relative;
    z-index: 1;
  }

  .icon-subtitle {
    margin-top: 0.25rem;
    color: $color-secondary;
  }

  img {
    width: 84px;
    margin-bottom: 20px;
  }
}

.ti-mouse:before {
  content: "\e68a";
}
.ti-heart-broken:before {
  content: "\e635";
}
.ti-headphone-alt:before {
  content: "\e719";
}
.ti-package:before {
  content: "\e687";
}

.features {
  div {
    display: inline-block;
    font-size: $h3FontSize;
    color: $color-secondary;
    font-family: "Playfair Display", serif;
    position: relative;
    padding: 1rem .25rem .5rem 0;

    @include media-breakpoint-down(sm) {
      padding: .75rem 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 2rem;
    }

    // ICON
    .icon {
      font-size: 3.2rem;
      padding-left: 10px;
      color: $color-secondary;
    }

    .feature-title {
      position: relative;
      display: inline-block;
      top: -5px;
      color: $color-secondary;
    }

  }
}
