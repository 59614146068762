.spinner-overlay {
  background: rgba(0,0,0, 0.55);
  height: 100vh;
  width: 100vw;
  position: fixed;
  left: 0;
  top: 0;
  z-index: 10;

  .spinner {
    position: relative;
    width: 80px;
    height: 80px;
    z-index: 11;

    div {
      position: absolute;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      background: #fff;
      animation-timing-function: cubic-bezier(0, 1, 1, 0);

      &:nth-child(1) {
        left: 10px;
        animation: spinner1 0.6s infinite;
      }
      &:nth-child(2) {
        left: 10px;
        animation: spinner2 0.6s infinite;
      }
      &:nth-child(3) {
        left: 32px;
        animation: spinner2 0.6s infinite;
      }
      &:nth-child(4) {
        left: 56px;
        animation: spinner3 0.6s infinite;
      }
    }
  }
}

@keyframes spinner1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes spinner3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes spinner2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}
