// Text and Icons in Product list
.product-list {
  padding: 0;
}

table.product-list {
  tr:last-child th:first-child {
    border-bottom-left-radius: 46px;
  }

  thead {
    th {
      font-weight: bold;
      border-top: none;
      border-bottom: 1px solid lighten($color-secondary, 12);
      width: 18%;

      &:first-of-type {
        width: 44%;
      }
    }
  }
  th {
    vertical-align: inherit;
    font-weight: 300;

    &[scope=col] {
      @include media-breakpoint-down(sm) { font-size: 1.0rem !important; }
      @include media-breakpoint-between(sm, md) { font-size: 1.2rem !important; }
      @include media-breakpoint-between(md, lg) { font-size: 1.3rem !important; }
      @include media-breakpoint-up(lg) { font-size: 1.4rem !important; }
      letter-spacing: 1px;
      color: #fff;
    }
    &[scope=row] {
      @include media-breakpoint-down(sm) { font-size: 0.6rem !important; }
      @include media-breakpoint-between(sm, md) { font-size: 0.65rem !important; }
      @include media-breakpoint-between(md, lg) { font-size: 0.75rem !important; }
      @include media-breakpoint-up(lg) { font-size: 1rem !important; }
    }
  }
  th, td {
    text-align: center;
    vertical-align: inherit;
    padding: .25rem;
    @include media-breakpoint-down(sm) { padding: 0.4rem 0.2rem !important; }
    @include media-breakpoint-between(sm, md) { padding: 0.6rem 0.4rem !important; }
    @include media-breakpoint-between(md, lg) { fpadding: 0.8rem 0.6rem !important; }
    @include media-breakpoint-up(lg) { padding: 1rem .8rem !important; }

    img {
      width: 24px;
      height: auto;

      @include media-breakpoint-down(sm) {
        width: 16px;
      }
    }
  }
  tbody {
    background: #fff;

    tr {
      &.first-row {
        border-bottom: 2px solid darken($color-secondary, 12);
      }

      &.first-row,
      &.last-row {
        background: lighten($color-accent, 20);

        th {
          background-image: linear-gradient(to right, lighten($color-secondary, 80), lighten($color-accent, 20));
        }
      }
      &:first-of-type {
        color: #000;
        height: 60px;
      }

      th {
        text-align: right;
      }

      td {
        min-width: 12%;
        max-width: 20%;
      }
    }
  }
  .button {
    @include media-breakpoint-down(sm) {
      font-size: 0.6rem;
      line-height: 1.2;
      padding: .7rem .5rem;
    }
  }

}

.product {
  border: 1px solid $color-secondary;
  padding: 2rem;
  margin-right: 1rem;
}

img {
  &.aside {
    padding: 1rem 2rem !important;
    max-width: 248px !important;
  }
}
