.logo {
  display: none;

  // NOTE: Leave border param commented out for debugging purposes
  @include media-breakpoint-down(sm) {
    width: 100px;
    //border: 1px solid green !important;
  }
  @include media-breakpoint-between(sm, md) {
    display: block;
    width: 120px;
    //border: 1px solid blue !important;
  }
  @include media-breakpoint-between(md, lg) {
    display: block;
    width: 120px;
    //border: 1px solid magenta !important;
  }
  @include media-breakpoint-up(lg) {
    display: block;
    width: 135px;
    //border: 1px solid red !important;
  }

  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}

.header-main {
  .logo {
    img {
      padding-bottom: .3rem;
    }
  }
}

.logo-mobile {
  display: block;
  width: 50px;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  a {
    display: block;
    width: 100%;
    height: 100%;
  }
}
