.landing-page {
  .banner-bg {
    filter: contrast(0.7);
  }

  .banner-text {
    position: relative;

    @include media-breakpoint-down(sm) {
      max-width: 100%;
      padding: 1rem;
    }
    @include media-breakpoint-between(sm, lg) {
      max-width: 480px;
      padding: 2rem;
    }
    @include media-breakpoint-up(lg) {
      max-width: 640px;
      padding: 4rem;
    }

    h1 {
      width: 100%;
      color: #fff;
      text-shadow: 0.05rem 0.09rem 0.15rem rgba(0, 0, 0, 0.7);
      text-align: left;
    }

    p.bold {
      color: #fff;
      font-family: $header-font-family;
      text-shadow: 0.03rem 0.07rem 0.05rem rgba(0, 0, 0, 0.9);
      margin-bottom: 0;

      @include media-breakpoint-down(sm) {
        font-size: 1.1rem;
        line-height: 1.5;
        letter-spacing: 0.5px;
        padding: 0.5rem auto;
      }
      @include media-breakpoint-between(sm, lg) {
        font-size: 1.2rem;
        line-height: 1.6;
        letter-spacing: 0.75px;
        padding: 1rem auto;
      }
      @include media-breakpoint-up(lg) {
        font-size: 1.35rem;
        line-height: 3 rem;
        letter-spacing: 1px;
        padding: 2rem auto;
      }
    }
  }

  .header,
  .footer {
    display: none;
  }

  .intro {
    width: 100%;
    position: relative;
    height: 100vh;
  }

  .overlay {
    position: relative;
    top: 0;
    padding-bottom: 0 !important;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(100, 100, 100, 0)),
      to(rgba(0, 0, 0, 0.4)),
      color-stop(0, rgba(20, 20, 20, 0.6))
    );
    opacity: 1;
    min-width: 100%;
    width: auto;
    height: auto;
    z-index: 1;
  }
}
