.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.flex-center {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}

.flex-wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
}

.z-index-top {
  z-index: 3;
}

.z-index-bot {
  z-index: 2;
}

.fixed {
  position: fixed;
  top: 0px;
  border-bottom: 10px solid $color-primary !important;
}

.hidden {
  display: none;
}

.visuallyhidden {
  display: block;
  opacity: 0;
}
