#report {
  border: 1px solid $color-gray-light;
  width: 100%;

  iframe {
    width: 100%;
    height: 2200px;

    // Breakpoints below ensure that report is not cut off on all screen sizes
    // NOTE: Leave border param commented out for debugging purposes
    @include media-breakpoint-down(sm) {
      height: 980px;
      // border: 1px solid green !important;
    }
    @include media-breakpoint-between(sm, md) {
      height: 1360px;
      // border: 1px solid blue !important;
    }
    @include media-breakpoint-between(md, lg) {
      height: 1900px;
      // border: 1px solid magenta !important;
    }
    @include media-breakpoint-up(lg) {
      height: 2700px;
      // border: 1px solid red !important;
    }
  }
}
