
.snip-layout {
  font-family: $body-font-family !important;
  padding: 10px !important;
  $border-radius: 10px;

  #snipcart-header {
    #snipcart-title {
      display: none !important;
    }
  }

  .snip-product__name {
    font-weight: 400 !important;
    text-transform: none !important;
  }

  .snip-header__total {
    font-family: $header-font-family !important;
    font-weight: 400 !important;
  }
  .snip-layout__close {
    font-size: 2.2em !important;
    right: 0.5em !important;
    top: 0.3em !important;
  }

  .snip-layout__main-container {
    border-radius: 10px;
  }
  .snip-header {
    height: 80px !important;
    background: url('../images/envue-square.svg') no-repeat 20px center !important;
    background-size: contain;
    background-color: lighten($color-primary, 3) !important;
    border-top-left-radius: $border-radius;
    border-top-right-radius: $border-radius;
  }
  .snip-table {
    //
  }
  .snip-actions {
    //
  }
  .snip-btn.snip-btn--highlight {
    color: #fff;
    background: $color-secondary;
  }

  #snipcart-footer {
    min-height: 60px;
    background-color: lighten($color-primary, 1) !important;
    border-bottom-left-radius: $border-radius;
    border-bottom-right-radius: $border-radius;
  }

  .snip-footer__copyright {
    display: none !important;
  }
  .snip-product__remove {
    font-size: 20px !important;
  }

  // Target individual form inputs
  div[data-for="company"],
  div[data-for="phone"],
  div[data-for="address2"] {
    display: none;
  }
}
