// Body content (everything BUT fonts)
body {
  background: $background-color;
}

img {
  max-width: 100%;
}

hr.spacer {
  padding: 2rem 0;
  border-top: 0;

  @include media-breakpoint-down(sm) {
    padding: 0.8rem 0;
  }
  @include media-breakpoint-between(sm, md) {
    padding: 1.4rem 0;
  }
}

ul, ol {
  margin-left: 18px;
}

// Terms and Privacy policy pages
.page-terms, .page-privacy {
  ul {
    li {
      list-style-type: none;
    }
  }
}
