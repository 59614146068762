// Main footer
.footer {
  // background: darken($color-primary, 10);
  background-image: linear-gradient(217deg, rgba(20,197,238,1), rgba(20,197,238,.5) 92%),
    linear-gradient(127deg, rgba(123,222,226,1), rgba(123,222,226,.5) 92%),
    linear-gradient(336deg, rgba(0,0,255,1), rgba(0,0,255,.5) 92%);
  padding-top: 20px;
  padding-bottom: 20px;
}

.footer-top {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;
  padding: 2rem 0 3rem;

  // Square footer logo
  .site-logo-footer {
    height: 60px;
    width: auto;

    &:hover {
      opacity: 0.8;
      cursor: pointer;
    }
  }

  ul.footer-nav {
    margin-top: 1.5rem;
    flex-direction: row;

    li {
      line-height: 1.75rem;
    }
  }

  @include media-breakpoint-up(sm) {
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
  }
  .footer-title {
    color: #ffffff;
    font-size: 1rem;
    font-family: $font-family-heading;
    @include media-breakpoint-up(sm) {
      margin: 0;
    }
  }
  ul {
    list-style: none;
    margin: 0;
    flex: 1;

    li {
      display: block;
      // margin-right: 10px;
      font-size: 0.8rem;
      color: #ffffff;
      &:last-of-type {
        margin-right: 0;
      }
      a {
        color: #ffffff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }
    }
    @include media-breakpoint-up(sm) {
      height: inherit;
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      align-items: center;
      justify-content: flex-end;
      li {
        list-style: none;
        a {
          display: inline-block;
          height: 40px;
          padding: 10px 8px 10px 8px;
          font-weight: 300;
        }
      }
    }
    @include media-breakpoint-up(md) {
      li {
        font-size: 0.9rem;
      }
    }
  }
}

//  Subfooter
.footer-sub {
  display: flex;
  justify-content: space-between;
  background: $color-gray;
  padding: 2rem 0;

  ul.contact-links {
    li {
      text-align: center;
    }
  }
  ul {
    list-style: none;
    flex-wrap: wrap;
    flex-direction: column;
    margin: 0;
    padding: 0;
    line-height: 1.6rem;
    width: 100%;

    li {
      list-style: none;
      display: block;
      font-size: 0.8rem;
      color: #fff;

      &:first-of-type {
        margin-left: 0;
      }
      &:last-of-type {
        margin-right: 0;
      }

      a {
        color: #fff;
        text-decoration: none;
        &:hover {
          text-decoration: underline;
        }
      }

      span {
        display: inline-block;
        height: 40px;
        padding: 10px 0px 10px 8px;
        font-weight: bold;
        color: #fff;
      }
    }

    @include media-breakpoint-up(sm) {
      li {
        display: inline-block;
      }
    }
  }
}
