.page-services-list {
  background-image: url('../images/services.svg');
  background-repeat: no-repeat;

  @include media-breakpoint-down(sm) {
    background-size: 104%;
    background-position: 32px 6% !important;
  }
  @include media-breakpoint-up(md) {
    background-size: 85%;
    background-position: 180px 80px !important;
  }
  @include media-breakpoint-up(lg) {
    background-size: 88%;
    background-position: 180px 50px !important;
  }
}
