.page {
  .icon {
    padding: .5rem 1rem;
    max-height: 6rem;
    align-self: center;
  }
}

p.card {
  background: #fefefe;
  padding: 2rem;
  border-radius: 5px;
}

.message {
  width: 100%;

  // OVERRIDE
  .row {
    margin: 0 0 0 0;
  }
  .col-sm-12, .col-md-6 {
    padding-right: 0;
    padding-left: 0;
  }

  .message-img-left {transform: translateX(4rem);}
  .message-img-right {transform: translateX(-4rem);}
  .message-img-left,
  .message-img-right {
    box-shadow:
      0 15px 35px rgba(50, 50, 93, 0.1),
      0 5px 15px rgba(0, 0, 0, 0.07);

    img {
      width: 100%;
    }

    @include media-breakpoint-down(sm) {
      transform: translateX(0);
      width: 100%;
    }
  }
  .message-txt-right { transform: translateX(-4rem); }
  .message-txt-left { transform: translateX(4rem); }
  .message-txt-right,
  .message-txt-left {
    padding: 2rem 3rem;
    background-image: linear-gradient(217deg, rgba(20,197,238,1), rgba(20,197,238,.5) 92%),
      linear-gradient(127deg, rgba(123,222,226,1), rgba(123,222,226,.5) 92%),
      linear-gradient(336deg, rgba(0,0,255,1), rgba(0,0,255,.5) 92%);

    @include media-breakpoint-down(sm) {
      transform: translateX(0);
      width: 100%;
    }
  }


}

.call {
  display: flex;
  position: relative;
  color: $secondary;
  flex: 1 100%;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  flex-direction: column;
  background-image: linear-gradient(217deg, rgba(20,197,238,1), rgba(20,197,238,.5) 92%),
      linear-gradient(127deg, rgba(123,222,226,1), rgba(123,222,226,.5) 92%),
      linear-gradient(336deg, rgba(0,0,255,1), rgba(0,0,255,.5) 92%);

  .white {
    background-image: #fff;
  }

  @include media-breakpoint-down(sm) {
    // flex-direction: row;
    // width: 80%;
    &.call-wide {
      // width: 100%;
    }
  }
  @include media-breakpoint-up(md) {
    // width: 75%;
    &.call-wide {
      // width: 100%;
    }
  }
  @include media-breakpoint-up(lg) {
    // width: 50%;
    &.call-wide {
      // width: 100%;
    }
  }

  .call-box-top,
  .call-box-bottom {
    padding: 3rem;
    @include media-breakpoint-down(sm) {
      padding: 1rem 2rem;
    }
  }
  .call-box-top {
    flex: 1 0 auto;
    // font-size: 1.1rem;
    line-height: 1.75;
    font-weight: 400;
    color: #eee;
  }
  .call-box-bottom {
    flex: 0 0 auto;
  }
  .call-name {
    font-size: 1.1rem;
    font-weight: bold;
  }
  svg {
    fill: lighten($secondary, 40%);
    position: absolute;
    bottom: -9px;
    right: 0;
    width: 100px;
    height: 100px;
    @include media-breakpoint-up(sm) {
      width: 120px;
      height: 120px;
    }
  }
}
