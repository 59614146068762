.social-media-list,
.social-media-list-small {
  height: inherit;
  display: flex;
  list-style: none;
  margin: 0;
  padding: 0;
  // justify-content: flex-start;

  .svg-icon {
    width: 26px;
    height: 26px;
    display: inline-block;
    fill: #{$color-primary};
    margin-right: -6px;
    vertical-align: text-top;
  }

  li {
    padding: 0;
    list-style: none;
  }
}
