// Bootstrap
@import 'bootstrap/functions';
@import 'bootstrap-variables';
@import 'bootstrap/variables';
@import 'bootstrap/mixins';
@import 'bootstrap/root';
@import 'bootstrap/reboot';
@import 'bootstrap/grid';
@import 'bootstrap/type';
@import 'bootstrap/tables';
@import 'bootstrap/dropdown';
@import 'bootstrap/utilities';

// Added by Mihai
@import 'mixins';
@import 'variables';
@import 'fonts';
@import 'helpers';

// NEEDS CLEANUP!
@import 'components/medical-theme';

// Libraries
@import 'libraries/hamburgers/hamburgers';

// Components
// TODO: Determine what is redundant in this block in future PR
@import 'components/page';
@import 'components/header';
@import 'components/footer';
@import 'components/logo';
@import 'components/main-menu';
@import 'components/main-menu-mobile';
@import 'components/social';
@import 'components/hamburger';
@import 'components/buttons-and-forms';
@import 'components/content';
@import 'components/intro';
@import 'components/strip';
@import 'components/whitebox';
@import 'components/feature';
@import 'components/landing-page';
@import 'components/page-svg-background';
@import 'components/loading-spinner';

// Added by Mihai
@import 'components/dashboard';
@import 'components/firebaseui';
@import 'components/products';
@import 'components/snipcart';
@import 'components/modal';
@import 'components/social';
@import 'components/cards';

// Pages
@import 'pages/home';
@import 'pages/posts';
@import 'pages/testimonials/testimonials';
@import 'pages/services/page-services-list';
@import 'pages/services/services';
