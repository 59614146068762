.header {
  min-height: 80px;
  z-index: 10;
  padding: 1rem 0;
  background: lighten($color-primary, 50);
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0 2px 10px rgba(50, 50, 93, 0.2);
  z-index: 10;

  .container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .header-social {
    height: inherit;
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }

  &.header-main {
    background: rgba(250, 250, 250, 1) !important;
  }
}
