//
// Variables
// Color Scheme, Typography and the like.
//
// Color scheme
// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=53n0u0kMUVJnoUpuaVoURBzT2qs */

$color-primary-0: #03C2EF;	// Main Primary color */
$color-primary-1: #44CBEB;
$color-primary-2: #14C5EE;
$color-primary-3: #0190B2;
$color-primary-4: #016A82;

$color-secondary-1-0: #0D3EF1;	// Main Secondary color (1) */
$color-secondary-1-1: #5576EE;
$color-secondary-1-2: #2954F1;
$color-secondary-1-3: #042CBE;
$color-secondary-1-4: #04228F;

$color-secondary-2-0: #00F440;	// Main Secondary color (2) */
$color-secondary-2-1: #41F270;
$color-secondary-2-2: #0EF44A;
$color-secondary-2-3: #00CC36;
$color-secondary-2-4: #009D29;

// As RGBa codes */

$rgba-primary-0: rgba(  3,194,239,1);	// Main Primary color */
$rgba-primary-1: rgba( 68,203,235,1);
$rgba-primary-2: rgba( 20,197,238,1);
$rgba-primary-3: rgba(  1,144,178,1);
$rgba-primary-4: rgba(  1,106,130,1);

$rgba-secondary-1-0: rgba( 13, 62,241,1);	// Main Secondary color (1) */
$rgba-secondary-1-1: rgba( 85,118,238,1);
$rgba-secondary-1-2: rgba( 41, 84,241,1);
$rgba-secondary-1-3: rgba(  4, 44,190,1);
$rgba-secondary-1-4: rgba(  4, 34,143,1);

$rgba-secondary-2-0: rgba(  0,244, 64,1);	// Main Secondary color (2) */
$rgba-secondary-2-1: rgba( 65,242,112,1);
$rgba-secondary-2-2: rgba( 14,244, 74,1);
$rgba-secondary-2-3: rgba(  0,204, 54,1);
$rgba-secondary-2-4: rgba(  0,157, 41,1);
$background-color: #FFF !default;


// SET COLOR VARIABLES
$color-primary: #03C2EF;
$color-secondary: #045A8E;

$color-primary-dark: $color-primary-4;
$color-secondary-dark: $color-secondary-2-4;

$color-tertiary: $color-secondary-2-2;
$color-accent: #A7CCED;

$link-color: lighten($color-primary,10);
$link-color-hover: darken($color-primary,10);

$color-blue-light: lighten($color-primary, 15);
$color-blue-lighter: lighten($color-blue-light, 15);

$color-gray: #545E75 !default;
$color-gray-light: lighten($color-gray, 10%);
$color-gray-lighter: lighten($color-gray, 20%);
$color-gray-dark: darken($color-gray, 15%);
$color-gray-darker: darken($color-gray, 55%);

$grey-color-dark: $color-gray-light;
$grey-color-light: $color-gray-darker;

$color-lite: $color-secondary-1-0;
$color-basic: $color-primary-2;
$color-pro: $color-secondary-2-4;
.color-free  { background-color: darken($color-gray, 10); }
.color-lite  { background-color: lighten($color-lite, 20); }
.color-basic { background-color: darken($color-basic, 10); }
.color-pro   { background-color: lighten($color-pro, 10); }
