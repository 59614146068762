@mixin variable-intro-header($m) {
  .value-prop {
    @include media-breakpoint-down(sm) {
      font-size: 1.6rem;
      line-height: 1.7;
      width: 80%;
    }
    @include media-breakpoint-between(sm, md) {
      font-size: 1.8rem;
      line-height: 2;
      width: 85%;
    }
    @include media-breakpoint-between(md, lg) {
      font-size: 2rem;
      line-height: 2.2;
      width: 90%;
    }
    @include media-breakpoint-up(lg) {
      font-size: 2.2rem;
      line-height: 1.8;
      width: 92%;
    }
  }
}

.intro {
  position: relative;
  height: 70vh;

  .banner-text {
    height: 100%;
    width: 100%;
    position: absolute;
    margin: 0 auto;
    z-index: 3;
    padding: 3rem 0;

    .site-heading {
      position: relative;
      color: #fff;
      margin: auto;
      font-weight: 200;
      letter-spacing: 0.75px;
      line-height: 1.2;
      font-family: $font-family-heading;
      text-shadow: 0.05rem 0.09rem 0.15rem rgba(0, 0, 0, 0.6);
    }
  }

  @include media-breakpoint-down(sm) {
    h1 {
      font-size: 2.2rem !important;
    }
  }
  @include media-breakpoint-between(sm, md) {
    h1 {
      font-size: 3rem !important;
    }
  }
  @include media-breakpoint-between(md, lg) {
    h1 {
      font-size: 3.3rem !important;
    }
  }
  @include media-breakpoint-up(lg) {
    h1 {
      font-size: 3.5rem !important;
    }
  }

  .overlay {
    position: relative;
    top: 0;
    padding-bottom: 0 !important;
    background: -webkit-gradient(
      linear,
      left top,
      left bottom,
      from(rgba(100, 100, 100, 0)),
      to(rgba(0, 0, 0, 0.1)),
      color-stop(0, rgba(20, 20, 20, 0.3))
    );
    opacity: 1;
    min-width: 100%;
    width: auto;
    height: auto;
    z-index: 1;
  }

  .banner-bg {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
    background-image: url(/assets/images/header-alt.jpg);
    background-size: cover;
    background-position: 50% center;
    background-position-x: 50%;
    background-position-y: center;
  }
}
