@mixin whitebox($padding: 10px) {
  border: 1px solid #dcdcdc;
  border-radius: 3px;
  box-shadow: 0 1px 18px rgba(0, 0, 0, 0.2);
  background: #fff;
  padding: $padding;
}
.whitebox {
  @include whitebox();
}
