.testimonials-summary {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
  padding: 1rem 1.5rem;

  .testimonials-image {
    flex: 0;
    margin-right: 10px;
    img {
      width: 160px;
    }
  }
  .testimonials-meta {
    flex: 1;
    margin-bottom: 10px;

    p {
      margin: 0;
    }
  }
  .testimonials-title {
    font-size: $h5FontSize;
    margin-bottom: 12px;
  }
  .testimonials-name {
    font-weight: bold;
    display: inline-block;
  }
  .testimonials-jobtitle {
    display: inline-block;
    color: $secondary;
  }
  .testimonials-url a {
    color: darken($color-accent, 20);
    &:hover {
      color: $color-accent;
    }
  }
  .testimonials-content {
    flex: 1 0 100%;
  }
}

.page-testimonials-list {
  background-repeat: no-repeat;
  background-size: 115%;
  background-position: 49px -40px;
  @include media-breakpoint-up(sm) {
    background-size: 90%;
    background-position: 159px -40px;
  }
  @include media-breakpoint-up(md) {
    background-size: 85%;
    background-position: 199px -80px;
  }
  @include media-breakpoint-up(lg) {
    background-size: 60%;
    background-position: 100% -110px;
  }
}

blockquote {
  display: block;
  background: #fff;
  border-width: 2px 0 1px 0;
  border-style: solid;
  border-color: #efefef;
  padding: 1em .75em 0.5em .75em;
  margin: .75em 0;
  position: relative;

  &.knockout {
    color: #fff;
    font-size: 1.5rem;
    border-width: 2px 0 2px 0;
    background: none;
    // background: $color-secondary;
    margin: 1.75em 0;
    padding: 2em 1.25em 1.7em;

    &:before {
      height: 2.5rem;
      color: #fff;
      background: none;
    }
  }

  &:before {
    content: "\201C";
    position: absolute;
    top: 0em;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    width: 3rem;
    height: 2rem;
    font: 6em/1.08em "PT Sans", sans-serif;
    color: $color-primary;
    text-align: center;
  }
}
