//
// Fonts and related mixins
//

@mixin font($font-family, $font-file) {
  @font-face {
    font-family: $font-family;
    src: url($font-file+'.eot');
    src: url($font-file+'.eot?#iefix') format('embedded-opentype'),
         url($font-file+'.woff') format('woff'),
         url($font-file+'.ttf') format('truetype'),
         url($font-file+'.svg#aller') format('svg');
    font-weight: normal;
    font-style: normal;
  }
}
@include font('Averta Bold', '/assets/fonts/averta/AvertaStdSemibold');
@include font('Averta Regular',  '/assets/fonts/averta/AvertaStdRegular');
@include font('Averta Light',  '/assets/fonts/averta/AvertaStdLight');

$header-font-family: 'Averta Bold', 'Helvetica Neue', Helvetica, sans-serif !default;
$body-font-family: 'Averta Light', Arial, sans-serif !default;
$body-font-family-alt: 'Averta Regular', Arial, sans-serif !default;

// $body-font-family: $body-font-family-alt;


// External fonts
// @import url('https://fonts.googleapis.com/css?family=Playfair+Display:400,700,900&display=swap');
// @import url('https://fonts.googleapis.com/css?family=Poppins:200,300,400,700&display=swap');
// $header-font-family: 'Playfair Display', 'Helvetica Neue', Helvetica, sans-serif !default;
// $body-font-family: 'Poppins', Arial, sans-serif !default;

$text-color: $color-gray-dark;

$font-size-base: 1.1rem;

$h1FontSize: 2.1rem;
$h2FontSize: 1.9rem;
$h3FontSize: 1.6rem;
$h4FontSize: 1.4rem;
$h5FontSize: 1.2rem;
$h6FontSize: 1.1rem;

// Base typography settings
$base-font-weight: 400 !default;
$small-font-size:  $font-size-base * 0.5 !default;
$base-line-height: 1.5 !default;

body {
  font-family: $body-font-family !important;
  font-size: $font-size-base;
  color: $text-color;
  font-weight: 300;
  background: #fff;


  p {
    font-weight: 200;
    padding: 1rem 0;
    margin: 0;
    color: rgb(30, 30, 30);
    font-family: $body-font-family;
  }

  a {
    text-decoration: underline;
  }
}

// THEME DEFAULTS BELOW

ul,
ol {
  color: rgb(78, 78, 78);
  margin: 0;
  padding: 0;
  li {
    padding: 0;
    margin: 0;
  }
}

ul.no-bullet {
  list-style-type: none;
}


h1, h2, h3, h4, h5 {
  font-family: $header-font-family !important;
}
h4, h5, h6 {
  margin-bottom: 0;
}
h6 {
  font-family: $body-font-family;
}

h1 {
  font-size: $h1FontSize !important;
  line-height: 1.2;
  font-weight: $font-weight-light;
  color: $color-gray-dark;

  @include media-breakpoint-down(sm) {
    font-size: 0.8 * $h1FontSize;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 0.9 * $h1FontSize;
    line-height: 1.2;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: $h1FontSize;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.1 * $h1FontSize;
  }
}

.banner-text h1 {
  @include media-breakpoint-down(sm) {
    width: auto;
  }
  @include media-breakpoint-between(sm, md) {
    width: 60%;
  }
  @include media-breakpoint-between(md, lg) {
    width: 70%;
  }
  @include media-breakpoint-up(lg) {
    width: 50%;
  }
}

h2 {
  font-weight: $font-weight-light;
  line-height: 1.4;
  margin: 1.5rem 0;

  @include media-breakpoint-down(sm) {
    font-size: 0.8 * $h2FontSize;
  }
  @include media-breakpoint-between(sm, md) {
    font-size: 0.9 * $h2FontSize;
  }
  @include media-breakpoint-between(md, lg) {
    font-size: $h2FontSize;
  }
  @include media-breakpoint-up(lg) {
    font-size: 1.2 * $h2FontSize;
  }
}

h3 {
  font-size: $h3FontSize;
  font-weight: $font-weight-thin;
  line-height: 1.4;
  letter-spacing: 1px;
  margin: 1rem 0;
}

h2, h3, h4, h5 {
  font-weight: thin !important;
}
h4 {
  font-size: $h4FontSize;
  margin-top: .75rem;
}
h5 {
  font-size: $h5FontSize;
  margin-top: .5rem 0;
}
h6 {
  font-size: $h6FontSize;
  font-weight: bold;
}

strong {
  font-weight: 900;
  text-shadow: 0.015rem 0.015rem 0.05rem rgba(0,0,0,0.45);
}

// Body copy only
article {
  ul,
  ol {
    // color: $color-primary;
    font-weight: $font-weight-light;
    letter-spacing: 0.79px;
  }
}

.font-sm {
  $font-size-base: 0.8rem;
}

.light {
  color: #fff;
}
