.button {
  white-space: nowrap;
  cursor: pointer;
  display: inline-block;
  padding: 0.5rem 1.25rem;
  background-color: darken($color-secondary, 4);
  border-radius: none;
  font-size: 1rem;
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 0.03em;
  color: #fff;
  text-decoration: none;
  -webkit-transition: all 0.15s ease;
  transition: all 0.15s ease;
  border: none;

  &.color-free {
    background-color: darken($color-gray, 10);
  }
  &.color-lite {
    background-color: lighten($color-lite, 20);
  }
  &.color-basic {
    background-color: lighten($color-basic, 10);
  }
  &.color-pro {
    background-color: lighten($color-pro, 10);
  }

  &:hover {
    color: #fff;
    background: lighten($color-secondary, 2%) !important;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.08);
    text-decoration: none;
  }

  &.btn-sm {
    font-size: 0.8rem;
    padding: 0.5rem 0.9rem;
  }
  &.btn-md {
    font-size: 1.1rem;
    padding: 0.75rem 2rem;
  }
  &.btn-lg {
    font-size: 1.4rem;
    padding: 1.2rem 1.5rem;
  }
  &.btn-xl {
    font-size: 1.6rem;
    padding: 1.25rem 2rem;
    margin: 2rem;
    background: transparent;
    border: 2px solid #fff;
  }
  &.btn-xl-blue {
    font-size: 1.6rem;
    padding: 1.25rem 2rem;
    margin: 2rem;
  }
}

button #client-portal {
  height: inherit;
  display: inline-block;
  font-size: 0.9rem;
  font-weight: 300;
  text-transform: uppercase;
  color: #fff;
  text-decoration: none;
  padding: 0.5rem 1rem !important;
  background: $color-secondary;

  &:hover {
    color: #fff;
    box-shadow: 0 7px 14px rgba(50, 50, 93, 0.1), 0 3px 6px rgba(0, 0, 0, 0.2);
    text-decoration: none;
  }
}

input,
textarea {
  transition: all 0.4s ease;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: 2px solid $color-primary;
  padding: 0.5rem;
  margin-bottom: 1.25rem;
  width: 100%;
}

.toggle-content {
  display: none;
  height: 0;
  opacity: 0;
  overflow: hidden;
  transition: height 350ms ease-in-out, opacity 750ms ease-in-out;
}

.toggle-content.is-visible {
  display: block;
  height: auto;
  opacity: 1;
}

.form-result {
  flex-direction: column;
  position: relative;
  background: #fff;
  margin-bottom: 2.5rem;
  border-radius: 4px;

  p {
    font-size: 1rem;
  }

  &.active {
    padding: 2rem;
    margin-top: 0.5rem;
  }

  // Error-specific styling
  &.error {
    border: 1px solid $red;

    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $red;
      opacity: 0.8;
      padding: 0.75rem 0;
      margin: 0 auto;
    }

    p {
      color: $red;
    }
  }

  // Success-specific styling
  &.success {
    border: 1px solid $color-primary;

    h2,
    h3,
    h4,
    h5,
    h6 {
      color: $color-primary;
      padding: 0.75rem 0;
      margin: 0 auto;
    }

    p {
      color: $color-primary;
    }
  }
}

#form-social {
  margin-top: 1rem;
  -webkit-transition: opacity 350ms ease-in-out;
  -moz-transition: opacity 350ms ease-in-out;
  -o-transition: opacity 350ms ease-in-out;
  transition: opacity 350ms ease-in-out;

  p {
    margin-bottom: 0;
  }

  .social-media-list-small {
    .svg-icon {
      transform: scale(1.5);
      margin: 0 0.25rem;
      width: 24px;
      height: 24px;
      fill: $color-primary;
      padding: 0.15rem;

      &:hover {
        fill: lighten($color-primary, 20);
        transition: all 285ms ease-in 0s;
      }
    }
  }
}
