//
// Flex Cards (used for Services, Team Members, etc)
//

$card-margin: 3%;

.cards {
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  margin: 0 auto;
  justify-content: center;
  margin-left: $card-margin;

  .card {
    margin: 0 $card-margin 0 0;
    border-bottom-right-radius: 0.15rem;
    border-bottom-left-radius: 0.15rem;
    // border: 1px solid #ccc;
    box-shadow: 2px 2px 6px 0px  rgba(0,0,0,0.15);

    @include mq(phones) {
      flex: 0 0 (100% - 2 * $card-margin);
      margin-bottom: 1em;
    }
    @include mq(phonel) {
      margin-bottom: 1.5em;
    }
    @include mq(tabletp) {
      flex: 0 0 (50% - 4 * $card-margin);
      margin-bottom: 2em;
    }
    @include mq(tabletl) {
      flex: 0 0 (50% - 6 * $card-margin);
      margin-bottom: 2.5em;
    }


    img {
      max-width: 100%;
    }
    .card-title {
      padding-top: 1rem;
    }
    .card-body {
      padding: 1rem 2rem;
    }
    a.btn {
      background: $color-primary;
      border: 0;
      color: #fff;
      text-decoration: none;
      padding: 8px 10px;
      width: 100%;
      border-radius: 4px;
    }
  }
}
